import { useEffect } from 'react'

/**
 * @description Used to monitor user clicks on browser's back button
 * @param eventHandler An event handler to execute when back button is pressed
 * @returns null
 */
export default (eventHandler: (event: PopStateEvent) => void) => {
  useEffect(() => {
    window.addEventListener('popstate', eventHandler)
    return () => {
      window.removeEventListener('popstate', eventHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
