import { useEffect } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Provider as AuthProvider, useProfile, useSession, useToken } from '@drive/auth'
import { ErrorPage, useInitialize } from '@drive/shared'
import AppRouter from './AppRouter'

const App = () => {
  const { withInitialize, setInitialized } = useInitialize()
  const tokenData = useToken()
  const session = useSession()
  const profile = useProfile()

  useEffect(() => {
    ;(async () => {
      // check if there's token data and load user into the profile store
      if (tokenData) {
        await session.getUser(tokenData.userId).then(() => session.setData({ role: tokenData.userRole }))
        await profile.getUser(tokenData.userId)
      }
      setInitialized()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [])

  return withInitialize(<AppRouter />)
}

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <ErrorPage error={error} reset={resetErrorBoundary} />
)

export default () => {
  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ErrorBoundary>
  )
}
